import HtmlContentDiv from '@/components/html-div';
import { getStyle } from '@/utils/theme/fonts';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getColor } from '@/utils/theme/colors';

const defaultConfig = {
	height: '79px',
	opened: false,
};
export const FooterSeoContent = () => {
	const seo = useSelector((state: any) => state?.seo);
	const [viewState, setViewState] = useState(defaultConfig);
	useEffect(() => {
		if (!seo?.footerData) {
			setViewState(defaultConfig);
		}
	}, [seo?.footerData]);
	const { height, opened } = viewState;
	return seo?.footerData ? (
		<Box>
			<Box
				sx={{
					color: '#333333',
					...getStyle('Tiny-Regular'),
					overflow: 'hidden',
					'& p': {
						margin: '0px',
						marginBlockStart: '0',
						marginBlockEnd: '0',
					},
					'& h1': {
						...getStyle('Small-SemiBold'),
					},
					'li' : {
						marginBottom: '10px'
					}
				}}
			>
				<hr style={{ borderTop: '1px solid #F3F3F3', marginTop: '16px', marginBottom: '16px' }} />
				<Box
					sx={{
						height,
						'& h1': {
							...getStyle('Small-SemiBold'),
						},
						'& h2': { padding: '0 !important' },
					}}
				>
					<HtmlContentDiv data={seo?.footerData} />
				</Box>
			</Box>
			{!opened && (
				<Box
					onClick={() => {
						setViewState({
							height: 'initial',
							opened: true,
						});
					}}
					sx={{
						...getStyle('Small-SemiBold'),
						color: getColor('Minty 0'),
						margin: '10px 0 24px 0',
						fontFamily: 'Recline',
					}}
				>
					{'See more >'}
				</Box>
			)}
		</Box>
	) : (
		<></>
	);
};
