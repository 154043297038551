import { SeoActions } from "./types";

export const setSeoData = (data: any) => async (dispatch: any) => {
    dispatch({
        type: SeoActions.SEO_DATA_SUCCESS,
        payload: data
    });
};


export const resetSeoData = () => async (dispatch: any) => {
    dispatch({
        type: SeoActions.SEO_DATA_LOADING,
        payload: {
            footerData: "",
            title: "",
            description: "",
            schemas: []
        }
    });
};
