import GenericErrorScreen from '@/components/ErrorScreen';
import Footer from '@/components/Footers';
import LayoutComponent from '@/components/LayoutComponent';
import LayoutSkeleton from '@/components/LayoutComponent/layoutSkeleton';
import { useLocation } from '@/hooks/useLocation';
import { layoutService } from '@/services/layout-service';
import { setSeoData, resetSeoData } from '@/store/seo/actions';
import { dataLayerPushWithEvent } from '@/utils/dataLayer';
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function Home() {
	const [layoutData, setLayoutData] = useState<any>();
	const [errored, setErrored] = useState(false);
	const { selectedLocation } = useLocation();
	const { isLoggedIn } = useSelector((state: any) => {
		return state.user;
	});
	const dispatch: any = useDispatch();

	useEffect(() => {
		setLayoutData(null);
		dataLayerPushWithEvent('moe_event', {
			moe_event_name: 'Global Home Page Loaded',
		});
		layoutService
			.getHomeLayout()
			.then((res: any) => {
				setLayoutData(res.data.data);
			})
			.catch(() => {
				setErrored(true);
			});
		fetch('/api/seoContent?context=home_content')
			.then((resp) => resp.json())
			.then((data) => {
				dispatch(
					setSeoData({
						title:
							'Find comfort & style | Buy & rent furniture online| Furlenco',
						description:
							'Bring home designer furniture @ wallet-friendly prices. Now rent furniture or buy furniture online from Furlenco, with the freedom to return or sell-back anytime.',
						footerData: data.htmlContent,
					})
				);
			});
		return () => {
			dispatch(resetSeoData());
		};
	}, [selectedLocation, isLoggedIn]);

	return (
		<>
			<Container maxWidth='xl' sx={{ margin: '0 auto', padding: '0 32px' }}>
				{!errored ? (
					layoutData ? (
						<LayoutComponent {...layoutData} />
					) : (
						<LayoutSkeleton />
					)
				) : (
					<GenericErrorScreen />
				)}
			</Container>
			<Footer />
		</>
	);
}
