import React from 'react'
import { Box, Link } from '@mui/material';
import { getColor } from '@/utils/theme/colors';

type LinkNavigatorProps = {
  label: string;
  navigateTo: string;
}

const LinkNavigator: React.FC<LinkNavigatorProps> = (props: LinkNavigatorProps) => {
  const { navigateTo, label } = props;
  return (
    <Link href={navigateTo} sx={{ textDecoration: 'none', color: getColor('Gray -1') }}>
      <Box sx={{ cursor: 'pointer' }}>{label}</Box>
    </Link>
  )
}

export default LinkNavigator;