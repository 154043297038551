import { getStyle } from '@/utils/theme/fonts';
import FlexBox from '@/atomic-components/FlexBox';
import LinkNavigator from './component/link-navigator';
import { LencoImage } from '@/atomic-components/Image';
import LencoButton from '@/atomic-components/LencoButton';
import { Box, Container, Divider, Link } from '@mui/material';
import { LencoTypography } from '@/atomic-components/LencoTypography';
import { FooterSeoContent } from './component/footer-seo-content';
import { useLocation } from '@/hooks/useLocation';
// import Pill from './component/pill';

const cities = (ENV as any)?.CITIES;

const S3_BUCKET_URL =
	'https://assets.furlenco.com/s3-furlenco-images/2.0/social-media';

const Footer = ({ mt = '40px' }: { mt?: string }) => {
	const { selectedLocation } = useLocation();
	return (
		<Box
			sx={{
				background:
					'linear-gradient(360deg, #F2FAFB 0%, rgba(255, 255, 255, 0) 102.67%)',
				mt,
				...getStyle('P_Medium'),
				pb: '24px',
			}}
		>
			<Divider sx={{ borderTop: '1px solid #F3F3F3' }} />
			<Container maxWidth='xl'>
				<Box sx={{ padding: '0 5%', mt: '40px' }}>
					<FlexBox justifyContent='space-between'>
						<FlexBox direction='column'>
							<LencoImage
								lazy={false}
								containerSx={{ height: '20px', width: '188px' }}
								url='https://assets.furlenco.com/s3-furlenco-images/yoda/furlenco-logo.png'
							/>
							<FlexBox sx={{ mt: '26px', justifyContent: 'space-between' }}>
								<Link target='_blank' href='https://www.facebook.com/Furlenco'>
									<LencoImage
										lazy={false}
										containerSx={{
											height: '24px',
											width: '24px',
											cursor: 'pointer',
										}}
										url={`${S3_BUCKET_URL}/facebook-circular.jpg`}
									/>
								</Link>
								<Link
									target='_blank'
									href='https://www.instagram.com/furlenco/'
								>
									<LencoImage
										lazy={false}
										containerSx={{
											height: '24px',
											width: '24px',
											cursor: 'pointer',
										}}
										url={`${S3_BUCKET_URL}/insta.jpg`}
									/>
								</Link>
								<Link target='_blank' href='https://twitter.com/furlenco'>
									<LencoImage
										lazy={false}
										containerSx={{
											height: '24px',
											width: '24px',
											cursor: 'pointer',
										}}
										url={`${S3_BUCKET_URL}/twitter.jpg`}
									/>
								</Link>
								<Link
									target='_blank'
									href='https://www.linkedin.com/company/furlenco/'
								>
									<LencoImage
										lazy={false}
										containerSx={{
											height: '24px',
											width: '24px',
											cursor: 'pointer',
										}}
										url={`${S3_BUCKET_URL}/linkedin.jpg`}
									/>
								</Link>
							</FlexBox>
						</FlexBox>
						<Box
							display='grid'
							gridTemplateColumns={'repeat(2, auto)'}
							rowGap='24px'
							columnGap='100px'
							m='0 0 36px -12%'
						>
							<LinkNavigator label='Home' navigateTo='/' />
							{/* <LinkNavigator
								label='Careers'
								navigateTo='https://jobs.furlenco.com/'
							/> */}
							<LinkNavigator label='About Us' navigateTo='/about-us' />
							<LinkNavigator label='Buy Furniture' navigateTo='/buy' />
							<LinkNavigator
								label='Privacy Policy'
								navigateTo='/privacy-policy'
							/>
							{selectedLocation && (
								<LinkNavigator
									label='Rent Furniture'
									navigateTo={`/${selectedLocation.cityName.toLowerCase()}`}
								/>
							)}
							<LinkNavigator
								label='Terms and Conditions'
								navigateTo='/terms-and-conditions'
							/>
							<LinkNavigator
								label='Annual Returns'
								navigateTo='/annual-returns'
							/>
						</Box>
						<FlexBox direction='column'>
							<LencoTypography sx={{ mb: '20px', ...getStyle('H14_Regular') }}>
								NEED HELP?
							</LencoTypography>
							<Link
								href='https://help.furlenco.com/hc/en-us'
								sx={{ textDecoration: 'none' }}
								target={'_blank'}
							>
								<LencoButton
									sx={{ padding: '8px 32px' }}
									variant='contained'
									color='primary'
								>
									Help Center
								</LencoButton>
							</Link>
							{/*  */}
						</FlexBox>
					</FlexBox>
					<Divider sx={{ borderTop: '1px solid #F3F3F3', m: '24px 0' }} />
					<FlexBox justifyContent='space-between' alignItems='center'>
						<FlexBox direction='column'>
							<LencoTypography mb='12px'>CITIES WE DELIVER TO</LencoTypography>
							<LencoTypography
								maxWidth='875px'
								sx={{ ...getStyle('Small_Regular') }}
							>
								{Object.values(cities)?.map((x: any) => {
										return x.label;
									})
									.join(', ')}{' '}
							</LencoTypography>
						</FlexBox>
						<FlexBox justifyContent='space-between' height='50px'>
							<Link
								href='https://play.google.com/store/apps/details?id=com.furlenco.android&hl=en_IN&gl=US'
								sx={{ mr: '16px', cursor: 'pointer', width: '140px' }}
							>
								<LencoImage
									lazy={false}
									// containerSx={{ height: '40px', width: '140px', cursor: 'pointer' }}
									url={`${S3_BUCKET_URL}/google-play-stroe.jpg`}
								/>
							</Link>
							<Link
								href='https://apps.apple.com/in/app/furlenco/id1134919232'
								sx={{ cursor: 'pointer', width: '140px' }}
							>
								<LencoImage
									lazy={false}
									// containerSx={{ height: '40px', width: '140px', cursor: 'pointer' }}
									url={`${S3_BUCKET_URL}/apple-store.jpg`}
								/>
							</Link>
						</FlexBox>
					</FlexBox>
					{/* <LencoTypography mb='12px' mt='32px'>POPULAR SEARCHES</LencoTypography>
          <FlexBox pb='40px'>
            <Pill label='Dining Room Furniture' />
            <Pill label='Bedroom Furniture' />
            <Pill label='Appliances' />
            <Pill label='Study Tables' />
            <Pill label='Chairs' />
            <Pill label='Living Room Furniture' />
          </FlexBox> */}
					<FooterSeoContent />
				</Box>
			</Container>
		</Box>
	);
};

export default Footer;
