import { getWidget } from '@/utils/getWidget';
import { Box } from '@mui/material';
import { getColor } from '@/utils/theme/colors';
import { LayoutApiResponseData } from '@/services/layout-service/models/layout';
import { emitMoeEvent } from '@/utils/dataLayer';

interface LayoutComponentProps extends LayoutApiResponseData {}

function LayoutComponent(props: LayoutComponentProps) {
	const emitWidgetClick = (widget: any) => {
		emitMoeEvent('Widget Clicked', {
			widget_id: widget.id,
			widget_name: widget.widgetName,
		});
	};

	return (
		<Box>
			{Array.isArray(props.widgets) &&
				props.widgets.map((widget: any) => {
					const WidgetName = getWidget(widget.data?.template?.type);
					return (
						<Box
							key={widget.data?.template?.type}
							sx={{
								padding: '24px 0',
								width: '100%',
								background:
									widget?.data?.background?.type == 'GRADIENT_LINEAR'
										? widget?.data?.background?.value?.webGradient
										: widget?.data?.background?.value?.color ? getColor(widget?.data?.background?.value?.color) : 'transparent',
							}}
							onClick={() => emitWidgetClick(widget)}
						>
							{ (widget.data?.template?.type && WidgetName) ? (
								<WidgetName.component {...widget} />				
							) : (
								<></>
							)}
						</Box>
					);
				})}
		</Box>
	);
}

export default LayoutComponent;
