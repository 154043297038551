import FlexBox from '@/atomic-components/FlexBox';
import { Box, Skeleton } from '@mui/material';

function LayoutSkeleton() {
	return (
		<Box sx={{ overflow: 'hidden' }}>
			<Skeleton
				variant='rectangular'
				width={'100%'}
				height={372}
				sx={{ mb: '40px' }}
			/>
			<FlexBox
				alignItems='center'
				justifyContent='center'
				fullWidth
				mb='96px'
				mt='-72px'
			>
				<FlexBox
					justifyContent='center'
					alignItems='center'
					position={'relative'}
					sx={{ overflow: 'hidden', borderRadius: '8px' }}
				>
					<Skeleton
						variant='rectangular'
						width={'420px'}
						height={'71px'}
						sx={{ margin: '0px 0' }}
					/>
					<Skeleton
						variant='rectangular'
						width={'420px'}
						height={'71px'}
						sx={{ margin: '0px 0' }}
					/>
				</FlexBox>
			</FlexBox>
			<Box>
				<FlexBox sx={{ justifyContent: 'space-between', width: '100%' }}>
					<Skeleton variant='text' width={180} height={60} />
					<Skeleton variant='text' width={120} height={30} />
				</FlexBox>
				<FlexBox
					justifyContent={'start'}
					sx={{ overflow: 'scroll', margin: '10px 0', width: '100%' }}
				>
					<Skeleton
						variant='rectangular'
						width={310}
						height={120}
						sx={{ margin: '8px 16px 0 0' }}
					/>
					<Skeleton
						variant='rectangular'
						width={310}
						height={120}
						sx={{ margin: '8px 16px' }}
					/>
					<Skeleton
						variant='rectangular'
						width={310}
						height={120}
						sx={{ margin: '8px 16px' }}
					/>
					<Skeleton
						variant='rectangular'
						width={310}
						height={120}
						sx={{ margin: '8px 16px' }}
					/>
				</FlexBox>
			</Box>

			<Box sx={{ overflow: 'scroll' }}>
				<FlexBox sx={{ justifyContent: 'space-between', width: '100%' }}>
					<Skeleton variant='text' width={120} height={30} />
					<Skeleton variant='text' width={120} height={30} />
				</FlexBox>
				<FlexBox justifyContent={'start'} sx={{ margin: '10px 0' }}>
					<Skeleton
						variant='rectangular'
						width={280}
						height={120}
						sx={{ margin: '0 10px 0 0' }}
					/>
					<Skeleton
						variant='rectangular'
						width={280}
						height={120}
						sx={{ margin: '0 10px' }}
					/>
					<Skeleton
						variant='rectangular'
						width={280}
						height={120}
						sx={{ margin: '0 10px' }}
					/>
					<Skeleton
						variant='rectangular'
						width={280}
						height={120}
						sx={{ margin: '0 10px' }}
					/>
				</FlexBox>
			</Box>
			<Box sx={{ overflow: 'scroll' }}>
				<FlexBox sx={{ justifyContent: 'space-between', width: '100%' }}>
					<Skeleton variant='text' width={120} height={30} />
					<Skeleton variant='text' width={120} height={30} />
				</FlexBox>
				<FlexBox justifyContent={'start'} sx={{ margin: '10px 0' }}>
					<Skeleton
						variant='rectangular'
						width={300}
						height={120}
						sx={{ margin: '0 10px 0 0' }}
					/>
					<Skeleton
						variant='rectangular'
						width={300}
						height={120}
						sx={{ margin: '0 10px' }}
					/>
					<Skeleton
						variant='rectangular'
						width={300}
						height={120}
						sx={{ margin: '0 10px' }}
					/>
					<Skeleton
						variant='rectangular'
						width={300}
						height={120}
						sx={{ margin: '0 10px' }}
					/>
				</FlexBox>
			</Box>
		</Box>
	);
}

export default LayoutSkeleton;
